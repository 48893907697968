import { API_ROUTES, buildApiRoute } from '@shared/utils/apiRoutes';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { NotificationResponse } from '@type/notification';

export const notificationService = {
  async getNotification(
    token: string,
    accountId: string,
    isRead?: boolean,
  ): Promise<NotificationResponse> {
    const queryParams = isRead !== undefined ? `?isRead=${isRead}` : '';

    const path =
      buildApiRoute(API_ROUTES.NOTIFICATIONS, {
        accountId: accountId,
      }) + queryParams;
    const payload: APIPayload = {
      method: 'GET',
      path,
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching notification.');
    const notificationResponse = res.data.data as NotificationResponse;
    return notificationResponse;
  },

  async patchNotification(
    token: string,
    accountId: string,
    notificationId: string,
    isRead: boolean,
    device: string,
  ): Promise<NotificationResponse> {
    const path = buildApiRoute(API_ROUTES.NOTIFICATION, {
      accountId: accountId,
      notificationId: notificationId,
    });
    const payload: APIPayload = {
      method: 'PATCH',
      path,
      body: JSON.stringify({ isRead, device }),
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in patching notification.');
    const notificationResponse = res.data.data as NotificationResponse;
    return notificationResponse;
  },
};
