interface Address {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
}

interface ClientData {
  firstName: string;
  lastName: string;
  id: string;
  address: Address;
}

interface FamilyData {
  spouse: { fullName: string };
  dependents: { fullName: string }[];
}

interface ProcessedClient {
  key: string;
  firstName: string;
  lastName: string;
  city: string;
  state: string;
  familyCount: string;
}

export const processClients = (
  data: {
    clientData: ClientData;
    familyData: FamilyData;
  }[],
): ProcessedClient[] => {
  const clientDetails: ProcessedClient[] = [];

  data.forEach(({ clientData, familyData }, index) => {
    const { firstName, lastName, id, address } = clientData;

    let city = '';
    let state = '';
    if (address) {
      city = `${address.city || 'N/A'}`;
      state = `${address.state || 'N/A'}`;
    }

    let familyCount = familyData.spouse.fullName ? 1 : 0;
    familyCount += familyData.dependents.length;

    clientDetails.push({
      key: id,
      firstName,
      lastName,
      city,
      state,
      familyCount: familyCount.toString(),
    });
  });
  return clientDetails;
};
