import { AxiosResponse } from 'axios';

import sendAPIRequest from '@shared/services/sendAxiosRequest';
import { APIPayload } from '@type/api.type';

export async function requestAPI(
  apiPayload: APIPayload,
  token: string,
): Promise<AxiosResponse> {
  try {
    const authToken = token;

    const headers = {
      authorizationToken: authToken,
      origin: 'https://partner.regalcredit.com',
    };

    const apiRes = await sendAPIRequest(
      apiPayload.method,
      apiPayload.path,
      apiPayload.body,
      headers,
      apiPayload.params || {},
    );

    return apiRes;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    /* DEVELOPERS NOTE:
    - We can improve this error handler by returning the error message to the caller.
    */
    return error;
  }
}
