import React, { createContext, useReducer } from 'react';

import {
  NotificationItem,
  NotificationState,
  NotificationContextProps,
} from '@type/notification';

import { notificationService } from '../services/notificationService';

const initialState: NotificationState = {
  all: [],
  read: [],
  unread: [],
  loading: false,
  error: null,
};

type Action =
  | { type: 'NOTIFICATION_FETCH' }
  | {
      type: 'NOTIFICATION_FETCH_SUCCESS';
      payload: {
        all: NotificationItem[];
        read: NotificationItem[];
        unread: NotificationItem[];
      };
    }
  | { type: 'NOTIFICATION_FETCH_ERROR'; payload: string }
  | { type: 'NOTIFICATION_PATCH_SUCCESS'; payload: NotificationItem[] };

export const notificationReducer = (
  state: NotificationState,
  action: Action,
): NotificationState => {
  switch (action.type) {
    case 'NOTIFICATION_FETCH':
      return { ...state, loading: true };
    case 'NOTIFICATION_FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        all: action.payload.all,
        read: action.payload.read,
        unread: action.payload.unread,
      };
    case 'NOTIFICATION_FETCH_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'NOTIFICATION_PATCH_SUCCESS':
      return { ...state, all: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const NotificationContext = createContext<
  NotificationContextProps | undefined
>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  const fetchNotification = async (
    token: string,
    accountId: string,
    isRead?: boolean,
  ) => {
    dispatch({ type: 'NOTIFICATION_FETCH' });
    try {
      const response = await notificationService.getNotification(
        token,
        accountId,
        isRead,
      );

      const allData = response.data;

      dispatch({
        type: 'NOTIFICATION_FETCH_SUCCESS',
        payload: { all: allData },
      });
      return response;
    } catch (error) {
      dispatch({
        type: 'NOTIFICATION_FETCH_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const patchNotification = async (
    token: string,
    accountId: string,
    notificationId: string,
    isRead: boolean,
    device: string,
  ) => {
    try {
      const response = await notificationService.patchNotification(
        token,
        accountId,
        notificationId,
        isRead,
        device,
      );
      dispatch({ type: 'NOTIFICATION_PATCH_SUCCESS', payload: response.data });
    } catch (error) {
      dispatch({
        type: 'NOTIFICATION_FETCH_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{ state, fetchNotification, patchNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
