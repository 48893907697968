/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { AxiosResponse } from 'axios';

import { API_ROUTES, buildApiRoute } from '@shared/utils/apiRoutes';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { CreditSummary } from '@type/creditSummary.type';

export const creditSummaryService = {
  async getCreditSummary(
    clientId: string,
    token: string,
  ): Promise<AxiosResponse | any> {
    try {
      const path = buildApiRoute(API_ROUTES.CREDIT_SUMMARY, {
        clientId,
      });

      const payload: APIPayload = {
        method: 'GET',
        path,
      };

      const res = await requestAPI(payload, token);

      if (res.status == 200) {
        const creditSummary = res.data.data as CreditSummary;
        return creditSummary;
      } else {
        const errorMessage = res.data.error
          ? res?.data?.error.replace('BUREAUS CREDIT SUMMARY: ', '')
          : 'No available bureaus to manage.';
        const result = {
          status: res.status,
          message: errorMessage,
          data: res.data,
        };
        return result;
      }
    } catch (error) {
      throw new Error('Error in getting client summary.');
    }
  },
};
