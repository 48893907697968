import { useContext } from 'react';

import { SupportContext } from '../context/SupportContext';

export const useSupport = () => {
  const context = useContext(SupportContext);
  if (!context) {
    throw new Error('useSupport must be used within a ContextProvider');
  }
  return context;
};
