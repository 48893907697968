/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_ROUTES, buildApiRoute } from '@shared/utils/apiRoutes';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { Family, FamilyDetails } from '@type/family.type';

export const familyService = {
  async getFamily(token: string, clientId: string): Promise<Family> {
    const path = buildApiRoute(API_ROUTES.CLIENT_FAMILY, {
      clientId,
    });

    const payload: APIPayload = {
      method: 'GET',
      path,
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;

    if (!res) throw new Error('Error in fetching family list.');
    const family = res.data.data as Family;
    return family;
  },

  async getSingleFamily(token: string, familyProfile: string): Promise<Family> {
    const path = buildApiRoute(API_ROUTES.CLIENT_FAMILY_PROFILE, {
      familyProfile,
    });

    const payload: APIPayload = {
      method: 'GET',
      path,
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching family details.');
    const family = res.data.data as Family;
    return family;
  },

  async getFamilyDetails(
    token: string,
    clientId: string,
    familyId: string,
  ): Promise<any> {
    const path = buildApiRoute(API_ROUTES.CLIENT_FAMILY_MEMBER, {
      clientId,
      familyId,
    });

    const payload: APIPayload = {
      method: 'GET',
      path,
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching family detail.');
    const record = res.data.data as FamilyDetails;
    return record;
  },
};
