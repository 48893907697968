import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Surface, Button, ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import ButtonForm from '@shared/components/common/forms/ButtonForm';
import InputField from '@shared/components/common/forms/fields/InputField';
import Forms from '@shared/components/common/forms/Forms';
import { picklistService } from '@shared/services/picklistService';

import { useSupport } from '../hooks/supportHook';

export default function SupportForm() {
  const { postSupport } = useSupport();
  const [_error, setError] = useState({});
  const [formData, setFormData] = useState({
    supportType: '',
    subject: '',
    description: '',
  });
  const [cancelEdit, setCancelEdit] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [inputFieldValid, setInputFieldValid] = useState({
    supportType: false,
    subject: false,
    description: false,
  });
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [caseCreation, setCaseCreation] = useState(true);
  const [caseId, setCaseId] = useState('');
  const [caseNumber, setCaseNumber] = useState('');
  const [picklist, setPicklist] = useState({});
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const token = auth?.user?.access_token || '';

  useEffect(() => {
    const fetchPicklistData = async () => {
      try {
        const picklistResponse = await picklistService.getPicklist(
          token,
          'Case',
          'Type?filter=CustomerSupport_',
        );
        dataConverter(picklistResponse);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchPicklistData();
  }, []);

  const dataConverter = (data: any) => {
    const picklistConvert = data.map((data: any) => ({
      value: data.Label,
      label: data.Label,
    }));
    setPicklist(picklistConvert);
  };

  const showModal = () => {
    if (isFormValid) {
      setCancelEdit(!cancelEdit);
    } else {
      setTriggerValidation(true);
      alert('Please fill out the required fields.');
    }
  };
  const cancelButton = () => {
    setCancelEdit(!cancelEdit);
  };

  const handleValueChange = (key: string, value: unknown) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    setSaveLoading(true);
    try {
      const caseSupport = await postSupport(
        token,
        formData.supportType,
        formData.subject,
        formData.description,
        'Client Dashboard - Desktop',
        auth.user?.profile.contactId || '',
        auth.user?.profile.accountId || '',
      );
      setCaseId(caseSupport?.Id ?? '');
      setCaseNumber(caseSupport?.CaseNumber ?? '');
      setSaveLoading(false);
      setCaseCreation(false);
    } catch (error) {
      setError(error as Error);
      setSaveLoading(false);
    }
  };

  const checkFormValidity = () => {
    setIsFormValid(
      inputFieldValid.supportType &&
        inputFieldValid.subject &&
        inputFieldValid.description,
    );
  };

  useEffect(() => {
    checkFormValidity();
  }, [inputFieldValid]);

  const handleValidate = (field: string, isValid: boolean) => {
    setInputFieldValid(prevState => ({
      ...prevState,
      [field]: isValid,
    }));
  };

  const goBack = () => {
    window.location.reload();
  };

  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : caseCreation ? (
        <>
          <Forms title="Support Request" layerIndex={2}>
            <View style={{ zIndex: 3 }}>
              <InputField
                label="Support Type"
                size="25%"
                dropdownOptions={picklist}
                onValueChange={value =>
                  handleValueChange('supportType', value.value)
                }
                required={true}
                onValidate={valid => handleValidate('supportType', valid)}
                triggerValidation={triggerValidation}
              />
            </View>
            <View style={{ zIndex: 2 }}>
              <InputField
                label="Subject"
                size="50%"
                onValueChange={value => handleValueChange('subject', value)}
                required={true}
                onValidate={valid => handleValidate('subject', valid)}
                triggerValidation={triggerValidation}
                shortText={true}
              />
            </View>
            <View>
              <InputField
                label="Description"
                size="100%"
                multiline={true}
                multilineHeight={'18vh'}
                onValueChange={value => handleValueChange('description', value)}
                required={true}
                onValidate={valid => handleValidate('description', valid)}
                triggerValidation={triggerValidation}
              />
            </View>
          </Forms>
          <ButtonForm
            buttonText="Submit"
            isCancellable={false}
            saveFn={handleSubmit}
            hideModal={cancelEdit}
            showModal={showModal}
            cancelFn={cancelButton}
            isLoading={saveLoading}
          />
        </>
      ) : (
        <>
          <Surface elevation={1} style={styles.parentContainer}>
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                <Text variant="titleLarge" style={styles.mainText}>
                  Support Case Created
                </Text>
                <Text variant="titleMedium" style={styles.subText}>
                  Case Number: {caseNumber}
                </Text>
                <Button
                  mode="contained"
                  style={styles.buttonSave}
                  rippleColor="#ED662C"
                  textColor="white"
                  onPress={() => goBack()}>
                  Go Back
                </Button>
              </View>
            </View>
          </Surface>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  buttonSave: {
    backgroundColor: '#ED662C',
    borderColor: '#FF1818',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    marginTop: 10,
    width: 120,
  },
  mainText: {
    color: '#2B3674',
    fontWeight: '700',
    marginBottom: 20,
  },
  parentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    color: 'black',
    marginTop: 10,
    padding: 20,
  },
  subText: {
    color: '#2B3674',
    fontWeight: '700',
    margin: 5,
  },
});
