/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  Modal,
  Portal,
  Button,
  Text,
  ActivityIndicator,
} from 'react-native-paper';

import { DateModalComponentProps } from '@type/modalComponent.type';

export default function ModalComponent({
  modalTitle,
  childrenElements,
  visible,
  closeModal,
  submitModal,
}: DateModalComponentProps) {
  const [loading, setLoading] = useState(false);
  const cancelModal = () => {
    closeModal();
  };

  const handleSubmit = () => {
    setLoading(true);
    try {
      submitModal();
    } catch (error) {
      console.error('Submission error:', error);
    }
  };
  return (
    <>
      <Portal>
        <Modal
          onDismiss={cancelModal}
          visible={visible}
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <>
            {loading ? (
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  borderRadius: 10,
                  marginTop: 5,
                  width: '25vh',
                  height: '15vh',
                }}>
                <ActivityIndicator />
              </View>
            ) : (
              <>
                <View
                  style={{
                    display: 'flex',
                    backgroundColor: 'white',
                    borderRadius: 10,
                    justifyContent: 'center',
                    padding: 15,
                    width: '20vw',
                  }}>
                  <Text variant="titleLarge" style={styles.mainText}>
                    {modalTitle}
                  </Text>
                  {childrenElements}
                  <View
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      borderRadius: 10,
                      marginTop: 5,
                    }}>
                    <Button
                      onPress={cancelModal}
                      mode="contained"
                      style={{
                        backgroundColor: '#FFFFFF',
                        borderColor: '#ED662C',
                        borderRadius: 120,
                        borderWidth: 1,
                        margin: 10,
                      }}
                      rippleColor="#ED662C">
                      <Text style={{ color: '#ED662C' }}>Cancel</Text>
                    </Button>
                    <Button
                      onPress={handleSubmit}
                      mode="contained"
                      buttonColor="#ED662C"
                      style={{
                        justifyContent: 'center',
                        borderRadius: 120,
                        margin: 10,
                      }}
                      disabled={loading}>
                      <Text style={{ color: '#FFFFFF' }}>Submit</Text>
                    </Button>
                  </View>
                </View>
              </>
            )}
          </>
        </Modal>
      </Portal>
    </>
  );
}

const styles = StyleSheet.create({
  mainText: {
    color: '#2B3674',
    fontWeight: '700',
    padding: 10,
  },
});
