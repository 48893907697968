export const format = {
  capitalize: (input: string): string => {
    if (input) {
      return input
        .split(' ')
        .map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
    }

    return input;
  },
  age: (birthdate: Date): number => {
    const birthDate = new Date(birthdate);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  },
  greetings: (name: string) => {
    const firstname = name.includes(' ') ? name.split(' ')[0] : name; // gets the first word of the name
    const displayName =
      firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase();

    const userHour = new Date().getHours();

    const greetings =
      userHour >= 0 && userHour <= 12
        ? 'Good Morning'
        : userHour > 12 && userHour <= 18
          ? 'Good Afternoon'
          : 'Good Evening';

    return `${greetings}, ${displayName}`;
  },
  transformDateYYYYMMDD(dateString: string): string {
    const [month, day, year] = dateString.split('/');
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(
      2,
      '0',
    )}`;

    return formattedDate;
  },
};
