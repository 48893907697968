/* eslint-disable react-native/no-color-literals */
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Avatar } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import {
  processFamily,
  renderFamilyData,
} from '@features/Family/helpers/familyTable.helper';
import { useFamily } from '@features/Family/hooks/familyHook';

import { dataConverter } from './helpers/familySelectoreHelper';

export default function FamilySelector() {
  const { getFamily } = useFamily();
  const { getSingleFamily } = useFamily();
  const [_error, setError] = useState({});
  const [loading, setLoading] = useState(true);
  const [picklist, setPicklist] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [initials, setInitials] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  let { primary, familyMember, selectedFamily, activeId } =
    location?.state || {};

  if (!location?.state) {
    const localState = JSON.parse(
      atob(sessionStorage.getItem('state') as string),
    );
    ({ primary, familyMember, selectedFamily, activeId } = localState);
  }

  async function fetchFamilyAndDetails(
    auth: any,
    renderFamilyData: (data: any) => any,
    processFamily: (details: any) => any,
    primary: { key?: string; firstName: string },
    familyMember?: { firstName: string },
  ) {
    const initials =
      familyMember?.firstName?.charAt(0) ||
      primary?.firstName?.charAt(0) ||
      (familyMember ? 'F' : primary ? 'P' : ' ');

    const data = await getFamily(
      auth.user?.access_token || '',
      primary.key || '',
    );
    if (!data) {
      return { initials, familyDetails: [] };
    }

    const processedData = renderFamilyData(data);
    const familyProfile = processedData.map(family => family.profile);

    const familyDetails = await Promise.all(
      familyProfile.map(async id => {
        const singleFamily = await getSingleFamily(
          auth.user?.access_token || '',
          id,
        );
        return { singleFamily };
      }),
    );

    return { initials, familyDetails: processFamily(familyDetails) };
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { initials, familyDetails } = await fetchFamilyAndDetails(
          auth,
          renderFamilyData,
          processFamily,
          primary,
          familyMember,
        );
        setInitials(initials);
        setPicklist(dataConverter(familyDetails, primary));
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  const handleSelectOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleSelectChange = (selectedOption: any) => {
    const isPrimarySelected = selectedOption.value === `${primary.key}`;
    sessionStorage.setItem(
      'state',
      btoa(
        JSON.stringify({
          primary: primary,
          familyMember: selectedOption.familyMember,
          isFamilyMember: !isPrimarySelected,
          selectedFamily: selectedOption.label,
          activeId: selectedOption.value,
        }),
      ),
    );
    navigate(`/manage-credit`, {
      state: {
        primary: primary,
        familyMember: selectedOption.familyMember,
        isFamilyMember: !isPrimarySelected,
        selectedFamily: selectedOption.label,
        activeId: selectedOption.value,
      },
    });
    window.location.reload();
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <View style={{ marginRight: 10 }}>
              <Select
                options={picklist}
                isOpen={isOpen}
                placeholder={
                  selectedFamily || `${primary?.firstName} ${primary?.lastName}`
                }
                onMenuOpen={handleSelectOpen}
                onMenuClose={() => setIsOpen(false)}
                menuIsOpen={isOpen}
                onChange={handleSelectChange}
                styles={{
                  control: base => ({
                    ...base,
                    width: '10vw',
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    '&:hover': {
                      border: 'none',
                    },
                  }),
                  menu: base => ({
                    ...base,
                    backgroundColor: 'white',
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    display: 'none',
                  }),
                }}
              />
            </View>
            <View>
              <Avatar.Text
                size={35}
                label={initials}
                id="profile-loggedin"
                data-testid="profile-loggedin"
                role="status"
                onClick={handleSelectOpen}
              />
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    backgroundColor: 'transparent',
    borderRadius: 25,
    justifyContent: 'space-between',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: 25,
    justifyContent: 'space-between',
    boxShadow: '1px 1px 1px 1px rgb(221 221 221)',
  },
  viewIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
};
