import React, { createContext, useReducer } from 'react';

import {
  Support,
  SupportState,
  SupportContextProps,
  SupportProviderProps,
} from '@type/support.type';

import { supportService } from '../services/supportService';

const initialState: SupportState = {
  support: {} as Support,
  loading: false,
  error: null,
};

type Action =
  | { type: 'SUPPORT_POST' }
  | { type: 'SUPPORT_POST_SUCCESS'; payload: Support }
  | { type: 'SUPPORT_POST_ERROR'; payload: string };

export const supportReducer = (
  state: SupportState,
  action: Action,
): SupportState => {
  switch (action.type) {
    case 'SUPPORT_POST':
      return { ...state, loading: true };
    case 'SUPPORT_POST_SUCCESS':
      return { ...state, loading: false, support: action.payload };
    case 'SUPPORT_POST_ERROR':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const SupportContext = createContext<SupportContextProps | undefined>(
  undefined,
);

export const SupportProvider: React.FC<SupportProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(supportReducer, initialState);

  const postSupport = async (
    token: string,
    supportType: string,
    subject: string,
    description: string,
    caseOrigin: string,
    clientId: string,
    accountId: string,
  ) => {
    dispatch({ type: 'SUPPORT_POST' });
    try {
      const support = await supportService.postSupport(
        token,
        supportType,
        subject,
        description,
        caseOrigin,
        clientId,
        accountId,
      );
      dispatch({ type: 'SUPPORT_POST_SUCCESS', payload: support });
      return support;
    } catch (error) {
      dispatch({
        type: 'SUPPORT_POST_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <SupportContext.Provider value={{ state, postSupport }}>
      {children}
    </SupportContext.Provider>
  );
};
