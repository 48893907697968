import { Family } from '@type/family.type';

interface ProcessedFamily {
  key: string;
  firstName: string;
  lastName: string;
  age?: string;
  relationship: string;
}

export function renderFamilyData(data: Family) {
  const familyData: {
    key: string;
    fullName: string;
    relationship: string;
    profile: string;
  }[] = [];
  if (data.spouse.fullName) {
    familyData.push({
      key: 'spouse',
      fullName: data.spouse.fullName,
      relationship: 'Spouse',
      profile: data.spouse.profile.slice(1),
    });
  }
  data?.dependents?.forEach((dependent, index) => {
    if (dependent.fullName) {
      familyData.push({
        key: `dependent ${index}`,
        fullName: dependent.fullName,
        relationship: 'Dependent',
        profile: dependent.profile.slice(1),
      });
    }
  });
  return familyData;
}

export const processFamily = (
  data: {
    singleFamily: {
      id: string;
      firstName: string;
      lastName: string;
      familyMemberType: string;
      dateOfBirth: string;
      SSN: string;
      address: {
        street: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
  }[],
): ProcessedFamily[] => {
  const familyDetails: ProcessedFamily[] = [];
  data.forEach(({ singleFamily }) => {
    const { id, firstName, lastName, familyMemberType, dateOfBirth } =
      singleFamily;

    const ageCalculate = calculateAge(dateOfBirth);

    familyDetails.push({
      key: id,
      firstName: firstName,
      lastName: lastName,
      age: ageCalculate,
      relationship: familyMemberType,
    });
  });
  return familyDetails;
};

function calculateAge(birthDateString: string | Date): string {
  const birthDate = new Date(birthDateString);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age.toString();
}
