/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-raw-text */
import React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Button, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { useFamily } from '@features/Family/hooks/familyHook';
import DataTableComponent from '@shared/components/common/DataTableComponent';

import { processClients } from '../helpers/clientTable.helper';
import { useClient } from '../hooks/clientHook';

export default function ClientsTable() {
  const [items, setItems] = useState<any[]>([]);
  const { getClientDetails, getClient } = useClient();
  const { getFamily } = useFamily();
  const [_error, setError] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const auth = useAuth();

  async function fetchClientData(auth: any) {
    const allClients = await getClient(
      auth.user?.access_token || '',
      auth.user?.profile.contactId || '',
    );
    if (!allClients) {
      throw new Error('No clients found.');
    }

    const clientIds = allClients.map(client => client.id);
    const clientsAndFamily = await Promise.all(
      clientIds.map(async id => {
        const clientData = await getClientDetails(
          auth.user?.access_token || '',
          id,
        );
        const familyData = await getFamily(auth.user?.access_token || '', id);
        return { clientData, familyData };
      }),
    );

    return clientsAndFamily;
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const clientsAndFamily = await fetchClientData(auth);
        setItems(processClients(clientsAndFamily));
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  const handleNavigate = (
    primary: { firstName: string; lastName: string; id: string },
    isFamilyMember: boolean,
    activeId: string,
  ) => {
    sessionStorage.setItem(
      'state',
      btoa(JSON.stringify({ primary, isFamilyMember, activeId })),
    );
    navigate(`/manage-credit`, {
      state: { primary, isFamilyMember, activeId },
    });
  };

  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <DataTableComponent
          pageTitle="Manage Clients"
          tableData={items}
          tableTitles={[
            { key: 'firstName', title: 'First Name', sortable: true },
            { key: 'lastName', title: 'Last Name', sortable: true },
            { key: 'city', title: 'City', sortable: true },
            { key: 'state', title: 'State', sortable: true },
            { key: 'familyCount', title: 'Family Count', sortable: true },
          ]}
          cellRenderer={(value: any) => {
            return <Text style={styles.subText}>{value}</Text>;
          }}
          actionChildrenTitle="Actions"
          actionChildren={items => (
            <Button
              mode="contained"
              style={{
                backgroundColor: '#FFFFFF',
                borderColor: '#ED662C',
                borderRadius: 120,
                borderWidth: 1,
                height: 40,
              }}
              onPress={() => handleNavigate(items, false, items.key)}
              rippleColor="#ED662C">
              <Text style={{ color: '#ED662C' }}>Manage</Text>
            </Button>
          )}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  subText: {
    color: '#2B3674',
    fontSize: 15,
    fontWeight: '700',
  },
});
