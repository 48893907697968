import { useContext } from 'react';

import { CreditSummaryContext } from '../context/CreditSummaryContext';

export const useCreditSummary = () => {
  const context = useContext(CreditSummaryContext);
  if (!context) {
    throw new Error('useCreditSummary must be used within a ContextProvider');
  }
  return context;
};
