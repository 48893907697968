/* eslint-disable react-native/no-color-literals */
import React from 'react';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';

import { DataTablePaginationProps } from '@type/dataTable.type';

export default function DataTablePagination({
  pageNumbers,
  handlePageChange,
  startIndex,
  endIndex,
  items,
  currentPage,
  totalPages,
}: DataTablePaginationProps) {
  const MAX_VISIBLE_PAGES = 5;

  const renderPageNumbers = () => {
    const visiblePageNumbers = pageNumbers?.slice(
      Math.max(currentPage - Math.floor(MAX_VISIBLE_PAGES / 2), 0),
      Math.min(currentPage + Math.ceil(MAX_VISIBLE_PAGES / 2), totalPages),
    );

    const shouldShowStartEllipsis = (visiblePageNumbers ?? [])[0] > 1;
    const shouldShowEndEllipsis =
      (visiblePageNumbers ?? [])[visiblePageNumbers?.length - 1] < totalPages;

    return (
      <>
        {shouldShowStartEllipsis && (
          <>
            <Button
              onPress={() => handlePageChange(1)}
              textColor="rgba(237, 102, 44, 1)"
              rippleColor="#ED662C">
              1
            </Button>
            <Button disabled={true} textColor="#A3AED0">
              ...
            </Button>
          </>
        )}
        {visiblePageNumbers?.map((pageNumber: number) => (
          <Button
            key={pageNumber}
            onPress={() => handlePageChange(pageNumber)}
            disabled={pageNumber === currentPage}
            textColor="rgba(237, 102, 44, 1)"
            rippleColor="#ED662C">
            {pageNumber}
          </Button>
        ))}
        {shouldShowEndEllipsis && (
          <>
            <Button disabled={true} textColor="#A3AED0">
              ...
            </Button>
            <Button
              onPress={() => handlePageChange(totalPages)}
              textColor="rgba(237, 102, 44, 1)"
              rippleColor="#ED662C">
              {totalPages}
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <View>
        <Text style={{ color: '#A3AED0' }}>
          Showing {startIndex} - {endIndex} out of {items.length} results
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Button
          disabled={currentPage === 1}
          onPress={() => handlePageChange(currentPage - 1)}
          icon="arrow-left">
          <></>
        </Button>
        {renderPageNumbers()}
        <Button
          disabled={currentPage === totalPages}
          onPress={() => handlePageChange(currentPage + 1)}
          icon="arrow-right">
          <></>
        </Button>
      </View>
    </>
  );
}
