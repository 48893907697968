import {
  KC_ISSUER,
  KC_CLIENT_ID,
  KC_REALM,
  KC_REDIRECT_URL_IOS,
  KC_REDIRECT_URL_WEB,
  API_ENDPOINT,
  API_VERSION,
  KC_CLIENT_ID_OAUTH2,
} from '@env';

export const config = {
  kc: {
    issuer: KC_ISSUER,
    clientId: KC_CLIENT_ID,
    realm: KC_REALM,
    redirectUrl: {
      ios: KC_REDIRECT_URL_IOS,
      web: KC_REDIRECT_URL_WEB,
    },
  },
  api: {
    endpoint: API_ENDPOINT,
    version: API_VERSION,
  },
};

export const authConfig = {
  issuer: KC_ISSUER,
  clientId: KC_CLIENT_ID_OAUTH2,
  redirectUrl: KC_REDIRECT_URL_IOS,
  additionalParameters: {},
  scopes: ['openid', 'profile', 'email', 'user_attributes'],
  serviceConfiguration: {
    authorizationEndpoint: `${KC_ISSUER}/protocol/openid-connect/auth`,
    tokenEndpoint: `${KC_ISSUER}/protocol/openid-connect/token`,
    revocationEndpoint: `${KC_ISSUER}/protocol/openid-connect/revoke`,
    endSessionEndpoint: `${KC_ISSUER}/protocol/openid-connect/logout`,
    registrationEndpoint: `${KC_ISSUER}clients-registrations/openid-connect `,
    userInfo: `${KC_ISSUER}/protocol/openid-connect/userinfo`,
    account: `${KC_ISSUER}/account?referrer=${KC_CLIENT_ID_OAUTH2}`,
    resetPassword: `${KC_ISSUER}/account/account-security/signing-in??referrer=${KC_CLIENT_ID_OAUTH2}&`,
  },
  usePKCE: true,
  iosPrefersEphemeralSession: false,
};

export const accountConsoleConfig = {
  issuer: KC_ISSUER,
  clientId: 'account-console',
  redirectUrl: `${KC_ISSUER}/account`,
  additionalParameters: {},
  scopes: ['openid', 'profile', 'email', 'user_attributes'],
  serviceConfiguration: {
    authorizationEndpoint: `${KC_ISSUER}/account`,
    tokenEndpoint: `${KC_ISSUER}/protocol/openid-connect/token`,
    revocationEndpoint: `${KC_ISSUER}/protocol/openid-connect/revoke`,
    endSessionEndpoint: `${KC_ISSUER}/protocol/openid-connect/logout`,
  },
  usePKCE: true,
  iosPrefersEphemeralSession: false,
};
