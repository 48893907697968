import { API_ROUTES, buildApiRoute } from '@shared/utils/apiRoutes';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { ClientList } from '@type/client.type';
import { FamilyProfileApiResponse } from '@type/family.type';

export const clientService = {
  async getClient(token: string, contactId: string): Promise<ClientList[]> {
    const path = buildApiRoute(API_ROUTES.PARTNER_CONTACT_CLIENTS, {
      contactId,
    });

    const payload: APIPayload = {
      method: 'GET',
      path,
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching clients');
    const clientList = res.data.data as ClientList[];
    return clientList;
  },

  async getClientDetails(
    token: string,
    clientId: string,
  ): Promise<FamilyProfileApiResponse> {
    const path = buildApiRoute(API_ROUTES.CLIENT_FAMILY_MEMBER, {
      clientId: 'client',
      familyId: clientId,
    });

    const payload: APIPayload = {
      method: 'GET',
      path,
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;
    if (!res) throw new Error('Error in fetching client details');
    const accountDetails = res.data.data as FamilyProfileApiResponse;
    return accountDetails;
  },
};
