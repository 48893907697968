/*
DEVELOPERS NOTE:
- We can consider transferring the api call to the api service later on
*/
import { API_ROUTES, buildApiRoute } from '@shared/utils/apiRoutes';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { Picklist } from '@type/picklist.type';

export const picklistService = {
  async getPicklist(
    token: string,
    obj: string,
    field: string,
  ): Promise<Picklist> {
    const path = buildApiRoute(API_ROUTES.CONTROLS_PICKLIST, {
      object: obj,
      field,
    });

    const payload: APIPayload = {
      method: 'GET',
      path,
    };
    const res = (await requestAPI(payload, token)) as unknown as Record<
      string,
      Record<string, unknown>
    >;

    if (!res) throw new Error('Error in fetching picklist.');
    const pickList = res.data.data as Picklist;
    return pickList;
  },
};
