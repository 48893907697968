/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { API_ROUTES, buildApiRoute } from '@shared/utils/apiRoutes';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';

export const creditBlockActions = {
  async blockBureau(
    clientId: string,
    bureau: string,
    token: string,
  ): Promise<any> {
    try {
      const path = buildApiRoute(API_ROUTES.CREDIT_BLOCK, {
        bureau,
      });

      const payload: APIPayload = {
        method: 'POST',
        path,
        body: {
          clientId: clientId,
        },
      };

      const res = await requestAPI(payload, token);

      if (res.status == 200) {
        const result = res.data.data;
        return result;
      } else {
        return res;
      }
    } catch (error) {
      throw new Error('Failed to block client, try again later.');
    }
  },

  async unblockBureau(bureau: string, data: any, token: string): Promise<any> {
    try {
      const path = buildApiRoute(API_ROUTES.CREDIT_UNBLOCK, {
        bureau,
      });

      const payload: APIPayload = {
        method: 'POST',
        path,
        body: data,
      };

      const res = await requestAPI(payload, token);

      if (res.status == 200) {
        const result = res.data.data;
        return result;
      } else {
        return res;
      }
    } catch (error) {
      throw new Error('Failed to block client, try again later.');
    }
  },
};
