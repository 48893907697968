export const dataConverter = (data: any, primary: any) => {
  const groupedOptions = data.reduce((groups: any, member: any) => {
    const groupLabel = member.relationship;
    const firstName = member.firstName || 'Unknown';
    const lastName = member.lastName || 'Unknown';

    if (!groups[groupLabel]) {
      groups[groupLabel] = [];
    }

    groups[groupLabel].push({
      value: member.key,
      label: `${firstName} ${lastName}`,
      familyMember: member,
    });

    return groups;
  }, {});

  const primaryOption = {
    value: primary.key,
    label: `${primary.firstName || 'Unknown'} ${primary.lastName || 'Unknown'}`,
    familyMember: primary,
  };

  if (!groupedOptions['Primary']) {
    groupedOptions['Primary'] = [];
  }
  groupedOptions['Primary'].unshift(primaryOption);

  const picklistConvert = [
    {
      label: 'Primary',
      options: groupedOptions['Primary'],
    },
    ...Object.keys(groupedOptions)
      .filter(groupLabel => groupLabel !== 'Primary')
      .map(groupLabel => ({
        label: groupLabel,
        options: groupedOptions[groupLabel],
      })),
  ];

  return picklistConvert;
};
