/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Send an HTTP request using Axios.
 * @param method - HTTP method (GET, POST, PUT, PATCH, DELETE).
 * @param url - Target URL.
 * @param data - Optional payload for POST, PUT, PATCH methods.
 * @param headers - Optional headers for the request.
 * @param params - Optional query parameters for GET requests.
 * @param config - Additional Axios configuration (timeout, retries, etc.).
 */
const sendAPIRequest = async (
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  url: string,
  data: any = null,
  headers: Record<string, string> = { 'Content-Type': 'application/json' },
  params: Record<string, any> = {},
  token?: string,
  config: AxiosRequestConfig = {},
): Promise<AxiosResponse> => {
  try {
    if (token) {
      axios.defaults.headers.common = { Authorization: `bearer ${token}` };
    }

    const axiosConfig: AxiosRequestConfig = {
      method,
      url,
      data: ['POST', 'PUT', 'PATCH'].includes(method) ? data : undefined, // only attach data for relevant methods
      params: method === 'GET' ? params : undefined, // only attach params for GET requests
      headers: { ...headers },
      validateStatus: () => true, // Accept all status codes, we’ll handle them in the response
      ...config, // Merge with additional custom config if provided
    };

    const response = await axios(axiosConfig);

    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      console.log(
        `Request to ${url} returned status ${response.status}:`,
        response.data,
      );
      return response;
    }
  } catch (error: any) {
    console.log(
      `Error in ${method} request to ${url}:`,
      error.message || error,
    );
    throw new Error(
      error.message || `Unknown error occurred in ${method} request`,
    );
  }
};

export default sendAPIRequest;
