/* eslint-disable react-native/no-color-literals */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Button, Surface, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';

import DataTableComponent from '@shared/components/common/DataTableComponent';

import { renderFamilyData, processFamily } from '../helpers/familyTable.helper';
import { useFamily } from '../hooks/familyHook';

export default function FamilyTable() {
  const [items, setItems] = useState<any[]>([]);
  const { getFamily } = useFamily();
  const { getSingleFamily } = useFamily();
  const [_error, setError] = useState({});
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  let { primary, activeId } = location?.state || {};

  if (!location?.state) {
    const localState = JSON.parse(
      atob(sessionStorage.getItem('state') as string),
    );
    ({ primary, activeId } = localState);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getFamily(
          auth.user?.access_token || '',
          primary.key || '',
        );
        if (data) {
          const processedData = renderFamilyData(data);
          const familyProfile = processedData.map(family => family.profile);
          const familyDetails = await Promise.all(
            familyProfile.map(async id => {
              const singleFamily = await getSingleFamily(
                auth.user?.access_token || '',
                id,
              );
              return { singleFamily };
            }),
          );
          setItems(processFamily(familyDetails));
        }
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleNavigate = (
    familyMember: { firstName: string; lastName: string; key: string },
    isFamilyMember: boolean,
    selectedFamily: string,
    activeId: string,
  ) => {
    sessionStorage.setItem(
      'state',
      btoa(
        JSON.stringify({
          primary,
          familyMember,
          isFamilyMember,
          selectedFamily,
          activeId,
        }),
      ),
    );
    navigate(`/manage-credit`, {
      state: {
        primary,
        familyMember,
        isFamilyMember,
        selectedFamily,
        activeId,
      },
    });
  };

  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : items.length > 0 ? (
        <DataTableComponent
          pageTitle="Manage Family"
          tableData={items}
          tableTitles={[
            {
              key: 'firstName',
              title: 'First Name',
            },
            {
              key: 'lastName',
              title: 'Last Name',
            },
            {
              key: 'age',
              title: 'Age',
            },
            {
              key: 'relationship',
              title: 'Relationship',
            },
          ]}
          cellRenderer={(value: any) => {
            return <Text style={styles.subText}>{value}</Text>;
          }}
          actionChildrenTitle="Actions"
          actionChildren={items => (
            <Button
              mode="contained"
              style={{
                backgroundColor: '#FFFFFF',
                borderColor: '#ED662C',
                borderRadius: 120,
                borderWidth: 1,
                height: 40,
              }}
              onPress={() =>
                handleNavigate(
                  items,
                  true,
                  `${items.firstName} ${items.lastName}`,
                  items.key,
                )
              }
              rippleColor="#ED662C">
              <Text style={{ color: '#ED662C' }}>Manage</Text>
            </Button>
          )}
        />
      ) : (
        <Surface elevation={1} style={styles.parentContainer}>
          <Text variant="titleLarge">No family members enrolled.</Text>
        </Surface>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 20,
    width: '100%',
  },
  subText: {
    color: '#2B3674',
    cursor: 'auto',
    fontSize: 15,
    fontWeight: '700',
  },
});
