import { Box } from '@react-native-material/core';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Button, ScrollView } from 'react-native';
import { Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import Select from 'react-select';

import { dashboard } from '@assets/styles/pagesStyle';
import WelcomeUserContainer from '@shared/components/common/LoggedInWelcome';
import PageContainer from '@shared/components/common/PageContainer';

import { useNotification } from '../hooks/NotificationHook';

export default function NotificationMock() {
  const options = [
    { value: 'all', label: 'All' },
    { value: 'read', label: 'Read' },
    { value: 'unread', label: 'Unread' },
  ];

  const { fetchNotification, patchNotification } = useNotification();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const token = auth.user?.access_token;
  const account = auth.user?.profile.contactId;

  useEffect(() => {
    async function fetchData() {
      try {
        let response;
        if (selectedFilter === 'all') {
          response = await fetchNotification(token, account);
        } else if (selectedFilter === 'read') {
          response = await fetchNotification(token, account, true);
        } else {
          response = await fetchNotification(token, account, false);
        }

        const sortedData = response.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );

        setData(sortedData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [selectedFilter]);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedFilter(selectedOption.value);
  };

  const toggleReadStatus = async (item: any) => {
    const newStatus = !item.isRead;
    const notificationId = item.id;
    try {
      await patchNotification(token, account, notificationId, newStatus, 'Web');
      const updatedTests = data.map(data =>
        data.id === item.id ? { ...data, isRead: newStatus } : data,
      );
      setData(updatedTests);
    } catch (error) {
      console.error('Failed to toggle read status:', error);
    }
  };

  return (
    <PageContainer isClient={false}>
      <Box
        id="content"
        style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
        <View style={styles.welcomeHeader}>
          <Box>
            <WelcomeUserContainer />
            <Text variant="titleLarge">Test Data</Text>
            <Select options={options} onChange={handleSelectChange} />
            {loading ? (
              <>Hi</>
            ) : (
              <ScrollView>
                {data ? (
                  data.map(item => (
                    <View key={item.id} style={styles.card}>
                      <Text variant="titleMedium">{item.clientName}</Text>
                      <Text variant="bodyMedium">Level: {item.level}</Text>
                      <Text variant="bodyMedium">
                        Description: {item.shortDescription}
                      </Text>
                      <Text variant="bodySmall" style={styles.timestamp}>
                        Created At: {new Date(item.createdAt).toLocaleString()}
                      </Text>
                      <Button
                        title={item.isRead ? 'Mark as Unread' : 'Mark as Read'}
                        onPress={() => toggleReadStatus(item)}
                      />
                    </View>
                  ))
                ) : (
                  <Text variant="bodyMedium">No data available</Text>
                )}
              </ScrollView>
            )}
            {/* <Button title="Update Test Data" onPress={handlePatchTest} /> */}
          </Box>
        </View>
      </Box>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  boxMarginFlex: {
    flex: 1,
    margin: 10,
  },
  card: {
    borderColor: '#ccc',
    borderRadius: 5,
    borderWidth: 1,
    marginVertical: 5,
    padding: 10,
  },
  timestamp: {
    color: 'gray',
    fontSize: 12,
  },
  welcomeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
});
