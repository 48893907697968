import { API_ROUTES, buildApiRoute } from '@shared/utils/apiRoutes';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { Support } from '@type/support.type';

export const supportService = {
  async postSupport(
    token: string,
    supportType: string,
    subject: string,
    description: string,
    caseOrigin: string,
    clientId: string,
    accountId: string,
  ): Promise<Support | void> {
    try {
      const path = buildApiRoute(API_ROUTES.SUPPORT, {
        clientId: clientId,
      });

      const payload: APIPayload = {
        method: 'POST',
        path,
        body: {
          accountId,
          supportType: supportType,
          ticketType: 'Customer Support',
          status: 'New',
          caseOrigin: caseOrigin,
          subject: subject,
          description: description,
        },
      };

      const res = (await requestAPI(payload, token)) as unknown as Record<
        string,
        Record<string, unknown>
      >;

      if (!res) throw new Error('Error in sending support form.');
      const sendSupport = res.data.data as Support;
      return sendSupport;
    } catch (error) {
      throw new Error('Error in sending support form.');
    }
  },
};
