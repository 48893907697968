import React, { createContext, useState, ReactNode } from 'react';

export enum RESULT {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  WARNING = 'WARNING',
  UNDEFINED = '',
}

export const { SUCCESS, ERROR, WARNING, BLOCK, UNBLOCK, UNDEFINED } = RESULT;

type ClientResponseContextType = {
  handleClientResponse: (
    resultType: RESULT,
    message: string,
    resultHeader?: string,
    callback?: () => void,
    buttonText?: string,
  ) => void;
  modalVisibilityHandler: (visibility: boolean, clickedExit?: boolean) => void;
  resetModalResponse: () => void;
  resultType: RESULT;
  resultText: string;
  resultHeader?: string;
  modalVisible: boolean;
  buttonText?: string;
};

export const ClientResponseContext = createContext<
  ClientResponseContextType | undefined
>(undefined);

export const ClientResponseProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [resultType, setResultType] = useState<RESULT>(SUCCESS);
  const [resultText, setResultText] = useState<string>('');
  const [resultHeader, setResultHeader] = useState<string>();
  const [buttonText, setButtonText] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [onConfirmCallback, setOnConfirmCallback] = useState<() => void>(
    () => {},
  );

  const modalVisibilityHandler = (
    visibility: boolean,
    clickedExit?: boolean,
  ) => {
    setModalVisible(visibility);

    if (clickedExit) {
      resetModalResponse();
      return;
    } else if (
      !visibility &&
      typeof onConfirmCallback === 'function' &&
      !clickedExit
    ) {
      onConfirmCallback();
    }
  };

  const handleClientResponse = (
    resultType: RESULT,
    message: string,
    resultHeader?: string,
    callback?: () => void | null,
    buttonText?: string,
  ) => {
    setResultType(resultType);
    setResultText(message);
    setResultHeader(resultHeader);
    modalVisibilityHandler(true, false);
    if (callback) setOnConfirmCallback(() => callback);
    if (buttonText) setButtonText(buttonText);
  };

  const resetModalResponse = () => {
    setResultType(UNDEFINED);
    setResultHeader('');
    setButtonText('');
  };

  const ctx = {
    handleClientResponse,
    modalVisibilityHandler,
    resetModalResponse,
    resultType,
    resultText,
    resultHeader,
    modalVisible,
    buttonText,
  };

  return (
    <ClientResponseContext.Provider value={ctx}>
      {children}
    </ClientResponseContext.Provider>
  );
};
