/* eslint-disable react-native/no-color-literals */
// DataTableComponent.js

import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, DataTable, Icon, Surface, Text } from 'react-native-paper';
import { Link } from 'react-router-dom';

import DataTablePagination from '@shared/components/common/DataTablePagination';
import { DataTableComponentProps } from '@type/dataTable.type';

const DataTableComponent = ({
  pageTitle,
  buttonTitle,
  tableData,
  tableTitles,
  cellRenderer,
  actionChildren,
  actionChildrenTitle,
  iconBtn,
  stageStatus,
  stageStatusTitle,
}: DataTableComponentProps) => {
  const [items, setItems] = useState(tableData);

  const [sortCriteria, setSortCriteria] = useState({
    column: null,
    order: 'asc', // or 'desc'
  });

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  );

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleSort = column => {
    const order =
      sortCriteria.column === column && sortCriteria.order === 'asc'
        ? 'desc'
        : 'asc';
    setSortCriteria({ column, order });
  };

  const sortedItems = () => {
    if (!sortCriteria.column) {
      return items ? Array.from(items) : [];
    }

    return items
      ? Array.from(items).sort((a, b) => {
          const aValue = a[sortCriteria.column];
          const bValue = b[sortCriteria.column];

          if (aValue === bValue) {
            return 0;
          }

          if (sortCriteria.order === 'asc') {
            return aValue.localeCompare
              ? aValue.localeCompare(bValue)
              : aValue - bValue;
          } else {
            return bValue.localeCompare
              ? bValue.localeCompare(aValue)
              : bValue - aValue;
          }
        })
      : [];
  };

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, items.length);

  const renderSortIcon = column => {
    if (sortCriteria.column === column) {
      return (
        <View style={{ paddingLeft: 5 }}>
          <Icon
            source={
              sortCriteria.order === 'asc'
                ? 'sort-alphabetical-ascending'
                : 'sort-alphabetical-descending'
            }
            size={12}
            color="#A3AED0"
          />
        </View>
      );
    }
    return null;
  };

  useEffect(() => {
    setItems(prevItems => {
      return Array.isArray(prevItems) ? [...prevItems] : [];
    });
    const defaultSortColumn =
      tableTitles.find(title => title.defaultSort)?.key || null;
    setSortCriteria({ column: defaultSortColumn, order: 'asc' });
  }, [tableTitles]);

  return (
    <>
      <View>
        <Surface elevation={1} style={styles.parentContainer}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text variant="titleLarge" style={styles.mainText}>
              {pageTitle}
            </Text>
            {buttonTitle ? (
              <Button
                mode="contained"
                buttonColor="#ED662C"
                style={{ justifyContent: 'center', borderRadius: 120 }}
                icon={iconBtn}>
                {buttonTitle === 'Add New Client' ? (
                  <Link
                    to="/add-new-client"
                    style={{ color: 'white', textDecoration: 'none' }}>
                    {buttonTitle}
                  </Link>
                ) : (
                  buttonTitle
                )}
              </Button>
            ) : (
              <></>
            )}
          </View>
          <DataTable>
            <DataTable.Header>
              {tableTitles.map(title => (
                <DataTable.Title
                  style={{ justifyContent: 'center' }}
                  key={title.key}
                  onPress={() => title.sortable && handleSort(title.key)}>
                  <Text style={styles.titleText}>{title.title}</Text>
                  {title.sortable && renderSortIcon(title.key)}
                </DataTable.Title>
              ))}
              {stageStatus && (
                <DataTable.Title style={{ justifyContent: 'center' }}>
                  <Text style={styles.titleText}>{stageStatusTitle}</Text>
                </DataTable.Title>
              )}
              {actionChildren && (
                <DataTable.Title style={{ justifyContent: 'center' }}>
                  <Text style={styles.titleText}>{actionChildrenTitle}</Text>
                </DataTable.Title>
              )}
            </DataTable.Header>

            {sortedItems()
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage,
              )
              .map(item => (
                <DataTable.Row key={item.key} style={{ cursor: 'auto' }}>
                  {tableTitles.map(title => (
                    <DataTable.Cell
                      key={title.key}
                      style={{ justifyContent: 'center' }}>
                      {cellRenderer
                        ? cellRenderer(item[title.key], title)
                        : item[title.key]}
                    </DataTable.Cell>
                  ))}
                  {stageStatus && (
                    <DataTable.Cell style={{ justifyContent: 'center' }}>
                      {stageStatus(item)}
                    </DataTable.Cell>
                  )}
                  {actionChildren && (
                    <DataTable.Cell style={{ justifyContent: 'center' }}>
                      {actionChildren(item)}
                    </DataTable.Cell>
                  )}
                </DataTable.Row>
              ))}
          </DataTable>
        </Surface>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 20,
          }}>
          <DataTablePagination
            pageNumbers={pageNumbers}
            handlePageChange={handlePageChange}
            startIndex={startIndex}
            endIndex={endIndex}
            items={items}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  mainText: {
    color: '#2B3674',
    fontWeight: '700',
    padding: 10,
  },
  parentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    color: 'black',
    marginTop: 10,
    padding: 10,
  },
  titleText: {
    color: '#A3AED0',
    fontSize: 12,
    fontWeight: '700',
  },
});

export default DataTableComponent;
