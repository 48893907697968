import React from 'react';
import { Text, TextStyle } from 'react-native';
import { useAuth } from 'react-oidc-context';
interface WelcomeUserProps {
  name?: string;
  style?: TextStyle;
}

const WelcomeUserContainer: React.FC<WelcomeUserProps> = ({ name, style }) => {
  let displayName = name;

  if (!name) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { user } = useAuth();
    displayName = user?.profile?.given_name || name;
  } else {
    displayName = name.split(' ')[0];
  }

  const userHour = new Date().getHours();

  const greetings =
    userHour >= 0 && userHour <= 12
      ? 'Good Morning'
      : userHour > 12 && userHour <= 18
        ? 'Good Afternoon'
        : 'Good Evening';

  return (
    <>
      <Text testID="text-greetings" style={style}>
        {greetings}, {displayName}!
      </Text>
    </>
  );
};

export default WelcomeUserContainer;
