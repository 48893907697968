import React, { createContext, useReducer } from 'react';

import {
  CreditSummary,
  CreditSummaryState,
  CreditSummaryContextProps,
  CreditSummaryProviderProps,
} from '@type/creditSummary.type';

import { creditSummaryService } from '../services/creditSummaryService';

const initialState: CreditSummaryState = {
  creditSummary: {} as CreditSummary,
  error: null,
};

type Action =
  | { type: 'GET_CREDIT_SUMMARY' }
  | { type: 'GET_CREDIT_SUMMARY_SUCCESS'; payload: CreditSummary }
  | { type: 'GET_CREDIT_SUMMARY_ERROR'; payload: string };

export const supportReducer = (
  state: CreditSummaryState,
  action: Action,
): CreditSummaryState => {
  switch (action.type) {
    case 'GET_CREDIT_SUMMARY':
      return { ...state };
    case 'GET_CREDIT_SUMMARY_SUCCESS':
      return { ...state, creditSummary: action.payload };
    case 'GET_CREDIT_SUMMARY_ERROR':
      return { ...state, error: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const CreditSummaryContext = createContext<
  CreditSummaryContextProps | undefined
>(undefined);

export const CreditSummaryProvider: React.FC<CreditSummaryProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(supportReducer, initialState);

  const getCreditSummary = async (clientId: string, token: string) => {
    dispatch({ type: 'GET_CREDIT_SUMMARY' });
    try {
      const creditSummary = await creditSummaryService.getCreditSummary(
        clientId,
        token,
      );
      if (creditSummary) {
        dispatch({
          type: 'GET_CREDIT_SUMMARY_SUCCESS',
          payload: creditSummary,
        });
      }

      return creditSummary;
    } catch (error) {
      dispatch({
        type: 'GET_CREDIT_SUMMARY_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <CreditSummaryContext.Provider value={{ state, getCreditSummary }}>
      {children}
    </CreditSummaryContext.Provider>
  );
};
