import React, { createContext, useReducer } from 'react';

import {
  Main,
  Spouse,
  Dependent,
  Family,
  FamilyState,
  FamilyContextProps,
  FamilyProviderProps,
} from '@type/family.type';

import { familyService } from '../services/familyService';

const initialState: FamilyState = {
  family: {} as Family,
  selectedFamilyMember: null,
  loading: false,
  error: null,
};

type Action =
  | { type: 'FAMILY_GET' }
  | { type: 'FAMILY_GET_SUCCESS'; payload: Family }
  | { type: 'FAMILY_GET_SINGLE'; payload: Family }
  | { type: 'FAMILY_GET_ERROR'; payload: string }
  | { type: 'FAMILY_MEMBER_SET'; payload: Main | Spouse | Dependent | null };

export const familyReducer = (
  state: FamilyState,
  action: Action,
): FamilyState => {
  switch (action.type) {
    case 'FAMILY_GET':
      return { ...state, loading: true };
    case 'FAMILY_GET_SUCCESS':
      return { ...state, loading: false, family: action.payload };
    case 'FAMILY_GET_SINGLE':
      return { ...state, loading: false, family: action.payload };
    case 'FAMILY_GET_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'FAMILY_MEMBER_SET':
      return { ...state, selectedFamilyMember: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const FamilyContext = createContext<FamilyContextProps | undefined>(
  undefined,
);

export const FamilyProvider: React.FC<FamilyProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(familyReducer, initialState);

  const getFamily = async (token: string, clientId: string) => {
    dispatch({ type: 'FAMILY_GET' });
    try {
      const family = await familyService.getFamily(token, clientId);
      dispatch({ type: 'FAMILY_GET_SUCCESS', payload: family });
      return family;
    } catch (error) {
      dispatch({
        type: 'FAMILY_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const getSingleFamily = async (token: string, familyProfile: string) => {
    dispatch({ type: 'FAMILY_GET' });
    try {
      const family = await familyService.getSingleFamily(token, familyProfile);
      dispatch({ type: 'FAMILY_GET_SINGLE', payload: family });
      return family;
    } catch (error) {
      dispatch({
        type: 'FAMILY_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const getDetails = async (
    token: string,
    clientId: string,
    familyId: string,
  ) => {
    try {
      const family = await familyService.getFamilyDetails(
        token,
        clientId,
        familyId,
      );
      return family;
    } catch (error) {
      dispatch({
        type: 'FAMILY_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const selectFamilyMember = (member: Main | Spouse | Dependent | null) => {
    dispatch({ type: 'FAMILY_MEMBER_SET', payload: member });
  };

  return (
    <FamilyContext.Provider
      value={{
        state,
        getFamily,
        selectFamilyMember,
        getSingleFamily,
        getDetails,
      }}>
      {children}
    </FamilyContext.Provider>
  );
};
