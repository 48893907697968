import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import LoadingSpinner from '../layout/LoadingSpinner';

const Callback = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated && !auth.isLoading) {
      navigate('/clients');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [auth, navigate]);

  return <LoadingSpinner />;
};

export default Callback;
