/* eslint-disable react-native/no-color-literals */
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Appbar, Button, Menu } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import { useFamily } from '@features/Family/hooks/familyHook';
import {
  Family,
  FamilyMember,
  Main,
  Spouse,
  Dependent,
} from '@type/family.type';

interface IsClientProps {
  isClient: boolean;
}

export default function TopNav({ isClient }: IsClientProps) {
  const { state, getFamily, selectFamilyMember } = useFamily();

  const [_familyData, setFamilyData] = useState({} as Family);
  const [_loading, setLoading] = useState(true);
  const [_error, setError] = useState({});

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [familyMembers, setFamilyMembers] = useState<
    (Main | Spouse | Dependent)[]
  >([]);

  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const familyData = await getFamily(auth.user?.access_token || '');
        if (!familyData) return;
        setFamilyData(familyData);

        const { spouse, dependents } = familyData;
        if (!spouse || !dependents.length) return;
        const members = [
          {
            key: 'main',
            relationship: 'Main',
            fullName: familyData.name,
            // Update client profile when clientId is updated to be dynamic.
            profile: 'client/0015400000xqRF5AAM/family/0015400000xqRF5AAM',
          } as Main,
          { key: 'spouse', relationship: 'Spouse', ...spouse } as Spouse,
          ...dependents.map(
            (dependent: FamilyMember, index: number) =>
              ({
                key: `dependent-${index}`,
                relationship: 'Dependent',
                ...dependent,
              }) as Dependent,
          ),
        ];
        setFamilyMembers(members);
        selectFamilyMember(members[0]);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  const openMenu = () => setIsMenuVisible(true);
  const closeMenu = () => setIsMenuVisible(false);

  const handleMenuItemPress = (item: Main | Spouse | Dependent) => {
    selectFamilyMember(item);
    closeMenu();
  };

  return (
    <Appbar.Header style={{ backgroundColor: 'rgb(103, 124, 140)' }}>
      <Appbar.Content title="Partner Portal" titleStyle={styles.titleAppBar} />
      {isClient && !state.loading ? (
        <Menu
          visible={isMenuVisible}
          onDismiss={closeMenu}
          anchor={
            <Button
              onPress={openMenu}
              mode="elevated"
              labelStyle={styles.buttonLabel}>
              {state.selectedFamilyMember?.fullName || ''}
            </Button>
          }>
          {familyMembers.map((familyMember, index) => (
            <Menu.Item
              key={index}
              onPress={() => {
                handleMenuItemPress(familyMember);
              }}
              title={familyMember.fullName}
            />
          ))}
        </Menu>
      ) : (
        <></>
      )}
      <Appbar.Action icon="account" onPress={() => {}} />
    </Appbar.Header>
  );
}

const styles = StyleSheet.create({
  buttonLabel: {
    color: 'black',
  },
  titleAppBar: {
    color: 'white',
    fontSize: 30,
  },
});
